import { Sort } from '@angular/material/sort';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatTableDataSource } from '@angular/material/table';

import { filter, map, pairwise, throttleTime, withLatestFrom } from 'rxjs';

import { FocusableComponent } from '../focusable';
import { ISelectionState, ITableColumnConfig, ITableSort, ITableStyleType } from './interfaces';
import { TableVirtualScrollDataSource } from './vs-for-table/table-data-source';

@Component({
  selector: 'vi-ui-table',
  templateUrl: `./table.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> extends FocusableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @ViewChildren('componentCell', { read: ViewContainerRef }) containers: QueryList<ViewContainerRef>;

  @Input() public items: T[];
  @Input() public columnsConfig: ITableColumnConfig[];

  @Input() public styleType: ITableStyleType = ITableStyleType.DEFAULT;
  @Input() public selectable = false;
  @Input() public allowMultiSelect = true;
  @Input() public initialSelection = [];
  @Input() public isLoading = false;
  @Input() public isVirtualScrollActive = false;

  @Output() public itemSelected = new EventEmitter<ISelectionState<T>>();
  @Output() public allItemSelected = new EventEmitter<boolean>();
  @Output() public sort = new EventEmitter<ITableSort>();
  @Output() public loadMore = new EventEmitter<void>();

  public selection: SelectionModel<T>;
  public dataSource: MatTableDataSource<T>;
  public ITableStyleType = ITableStyleType;
  // Please avoid changing this to 'select' to prevent Material from applying styles that do not align with our design.
  public SELECT_COLUMN = 'select_column';
  public showHeaderSelection = false;

  private readonly ROW_HEIGHTS = {
    [ITableStyleType.DEFAULT]: 43,
    [ITableStyleType.COMPACT]: 33
  };

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef, private renderer: Renderer2) {
    super();
  }

  public get rowHeight() {
    return this.ROW_HEIGHTS[this.styleType];
  }

  public get displayedColumns() {
    const itemsColumns = this.columnsConfig.map(column => column.key);
    if (this.selectable) {
      return [this.SELECT_COLUMN, ...itemsColumns];
    }
    return itemsColumns;
  }

  public ngOnInit() {
    this.initializeDataSource();
    if (this.selectable) {
      this.selection = new SelectionModel<T>(this.allowMultiSelect, this.initialSelection);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.dataSource && changes.items) {
      this.dataSource.data = this.items;
      if (this.selectable) {
        this.selection = new SelectionModel<T>(this.allowMultiSelect, this.initialSelection);
      }
    }
  }

  public ngAfterViewInit() {
    this.setupVirtualScroll();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.length;
    return numSelected == numRows;
  }

  public toggleAllRows() {
    const isAllSelected = this.isAllSelected();
    isAllSelected ? this.selection.clear() : this.items.forEach(item => this.selection.select(item));
    this.allItemSelected.emit(!isAllSelected);
  }

  public toggleRow(item: T) {
    this.selection.toggle(item);
    this.itemSelected.emit({ item, isSelected: this.selection.isSelected(item) });
  }

  public sortData(sort: Sort) {
    const column = this.columnsConfig[sort.active];
    if (!column.sortMethod) {
      this.sort.emit({ key: column.key, direction: sort.direction });
      return;
    }
    const data = this.items.slice();
    if (!column.sortable || !sort.active || sort.direction === '') {
      return;
    }
    this.dataSource.data = data.sort((item1, item2) => {
      const isAsc = sort.direction === 'asc';
      const comparisonResult = column.sortMethod(item1, item2);
      return comparisonResult * (isAsc ? 1 : -1);
    });
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public setItemHovered(row, isHovered: boolean) {
    row.isHovered = isHovered;
  }

  private initializeDataSource() {
    if (this.isVirtualScrollActive) {
      this.dataSource = new TableVirtualScrollDataSource<T>(this.items);
      return;
    }
    this.dataSource = new MatTableDataSource<T>(this.items);
  }

  private setupVirtualScroll() {
    if (this.isVirtualScrollActive) {
      this.containers.changes.pipe(withLatestFrom((this.dataSource as TableVirtualScrollDataSource<T>).dataOfRange$)).subscribe(([, data]) => {
        this.setCustomColumns(this.sortedContainers, data);
      });
    } else {
      this.setCustomColumns(this.containers.toArray(), this.items);
    }

    this.viewport
      .elementScrolled()
      .pipe(
        map(() => this.viewport.measureScrollOffset('bottom')),
        pairwise(),
        filter(([previousOffset, currentOffset]) => {
          const isScrollingDown = currentOffset < previousOffset;
          const isNearBottom = currentOffset < this.rowHeight * 2; // within the last 2 rows
          return isScrollingDown && isNearBottom;
        }),
        throttleTime(200)
      )
      .subscribe(() => {
        this.loadMore.emit();
      });
  }

  /**
   * Sets columns that show component in the cells.
   *
   * For each column that should show component, it creates an instance of the component and assigns it to the corresponding container.
   * It also sets the component's inputs based on the `componentInputs` configuration.
   */
  private setCustomColumns(containers, data) {
    let index = 0;
    this.columnsConfig.forEach(column => {
      if (!column?.component) {
        return;
      }

      const componentClass = column.component;

      data.forEach(item => {
        const container = containers[index++];
        container.clear();
        const component = container.createComponent(componentClass);
        Object.entries(column.componentInputs).forEach(([key, factory]) => {
          component.instance[key] = factory(item);
        });
      });
      this.cdr.detectChanges();
    });
  }

  /**
   * Retrieves the containers sorted by columns.
   *
   * This getter method converts the containers into an array and divides them into columns based on the number of columns containing components.
   * It then sorts the containers within each column.
   *
   * @returns An array of sorted containers.
   *
   * @remarks
   * Sorting containers is crucial for correctly mapping the containers to their respective columns.
   */
  private get sortedContainers() {
    const containersArray = this.containers.toArray();
    const numContainersInEachColumn = containersArray.length / this.columnsWithComponent;
    const containersColumns = [];
    for (let i = 0; i < this.containers.length; i += numContainersInEachColumn) {
      containersColumns.push(this.containers.toArray().slice(i, i + numContainersInEachColumn));
    }

    const sortedContainers = containersColumns.map(array => this.sortContainers(array));
    return [].concat(...sortedContainers);
  }

  private sortContainers(containers: ViewContainerRef[]) {
    // Sort containers based on their position in the DOM, This is needed when scroll up and new container is created above the existing ones
    return containers.sort((a, b) =>
      a.element.nativeElement.compareDocumentPosition(b.element.nativeElement) === Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1
    );
  }

  private get columnsWithComponent() {
    return this.columnsConfig.reduce((count, column) => (column.component ? count + 1 : count), 0);
  }
}
